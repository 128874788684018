import { Typography } from '@mui/material'

const About = () => {
  return (
    <div style={{padding: '4em 4em', lineHeight: '1.5'}}>
      <Typography variant="h2">
        About me
      </Typography>
      <p sx={{padding: '2em 0'}}>
        I’m Aku Kettunen, 25, a masters' student from Jyväskylä. I did my Bachelor’s degree with the title of ”Computer vision in team sports”. I started my University studies at Aalto University, studying Engineering Physics and Mathematics. From there I moved to Jyväskylä to study Information System Sciences.       
      </p>
      <p>
        I’ve had the chance to work as a part time software developer at Kepit Systems Oy alongside my studies from 2019 to 2021. While there, I learned a ton about front-end development, particularly Vue.js. I also gained experience working with AWS, mainly technologies like S3, DynamoDB, EC2, API Gateway and Lambda functions. I also got to dip my toes into the world of functional programming with Elixir. 
      </p>
      <p>
        I pursued my own entrepreneurial goals in the form of ePesis, a video analysis software still used by every team in the main league of Finland. 
        That’s where my full-stack coding skills really got challenged. 
        When entering the market in December of 2019 I had to create the whole app from scratch, which was extremely benefical but challenging to my skills as a developer. 
        The whole process took approximately 300-400 hours. A year later I released ePesis 2.0. This was a more refined version again taking hundreds of hours to build. 
        Building a smooth CD-pipeline was important to me from the get go and I achieved this using GitHub and AWS CodePipeline. 
      </p>
      <p>
        In the future I would love to learn more on data-analysis and machine learning, especially computer vision. Also keeping up with the newest web development technologies is interesting. I would
        love to learn more on Typescript and Nuxt.js.
      </p>
      <p>
        I play Finnish baseball as a semi-pro at Kiteen Pallo. This hobby turned job has been a part of my life since I was eleven. I've made the all stars game - Itä-Länsi
        - twice now in 2021 and 2022. 
      </p>
      <Typography sx={{marginTop: '2em'}} variant="h5">
        Skills
      </Typography>
      <p>
        <strong>Strenghts:</strong> Vue, Vuex, Vuetify, Node.js, Express.js, MySQL, DynamoDB, CodePipeline, Lambda
      </p>
      <p>
        <strong>Can do:</strong> React, Python, Pandas, MatPlotLib, EC2, RDS
      </p>
    </div>
  )
}

export default About